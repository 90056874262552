<template>
  <v-container id="explorer" fluid tag="section" class="pt-0">
    <fondo-custom></fondo-custom>
    
    <!-- TITULO -->
    <v-row
      justify="center"
      class="sticky background-gradient top-title z-index-99"
    >
      <page-top-title>
        <template v-slot:toptitle-icon>mdi-book-open</template>
        <template v-slot:toptitle-text>{{ $t("EPG") }}</template>
        <template v-slot:toptitle-button>{{ $t(more_info)}}</template>
      </page-top-title>
    </v-row>

    <reactive-base
      :app="app"
      :key="searchBaseKey"
      :url="url"
      :credentials="credentials"
      :type="type"
    >
      <!-- RESULTADOS -->
      <v-row justify="center">
        <v-col cols="12" md="7">
          <v-card class="pa-0" rounded="xg">
            <v-card-title
              class="solid-heading rounded-t-lg py-0 px-3 px-sm-8 mb-0"
            >
              <data-search
                :innerClass="{ input: 'reactive-search-input' }"
                :showIcon="false"
                :URLParams="true"
                :showClear="false"
                :placeholder="searchPlaceholder"
                filterLabel="Name"
                componentId="SearchSensor"
                :autosuggest="true"
                :dataField="datafields"
                class=" grow"
              />
            </v-card-title>

            <v-card-text class="px-0">
              <v-row class="px-4 px-sm-8 search-bar mt-0 mx-0 active-filters py-4" justify-content="space-between" align="center">
                <like-radio-buttons
                  @buttonSelected="buttonSelected"
                  :activeButtonKey="app"
                  class="buttons d-flex flex-wrap"
                  :buttons="[
                    { text: 'Drugs', icon: 'pill', key: 'kb-drugs' },
                    { text: 'Genes', icon: 'dna', key: 'kb-genes' }
                  ]"
                />
              </v-row>
            </v-card-text>

            <v-row class="px-0 px-sm-4 mx-0">
              <v-list class="searchbase-results" rounded>
                <v-list-item-group color="primary">
                  <ReactiveList
                    componentId="result"
                    dataField="Critic_Score"
                    class="results"
                    :defaultQuery="defaultQuery"
                    :innerClass="{ resultStats: 'result-stat' }"
                    pagination
                    :size="8"
                    :URLParams="true"
                    :showResultStats="false"
                    :react="reactiveListReact"
                  >
                    <div slot="renderItem" slot-scope="{ item }">
                      <searchbase-drug-result-item
                        v-if="app == 'kb-drugs'"
                        :drug="item"
                      />
                      <searchbase-gene-result-item v-else :gene="item" />
                    </div>
                  </ReactiveList>
                </v-list-item-group>
              </v-list>
            </v-row>
          </v-card>
        </v-col>

        <!-- Filtros -->
        <v-col cols="12" md="4">
          <v-card class="pa-0" rounded="lg">
            <v-card-title class="solid-heading rounded-lg mb-4 py-3">
              <h3 class="white--text font-weight-regular">
                <v-icon color="white">mdi-filter</v-icon>Filters
              </h3>
            </v-card-title>
            <v-card-text>
              <selected-filters id="selected-filters">
                <div slot-scope="{ selectedValues, setValue, clearValues }">
                  <div v-for="componentId in Object.keys( getFilteredValues(selectedValues) )" :key="componentId" >
                    <div>
                      {{ selectedValues[componentId].showValue || selectedValues[componentId].value }}
                      <v-btn @click="clearFilter(componentId, setValue)" icon color="red">
                        <v-icon class="mt-n2">mdi-minus-circle</v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <div class="mt-2">
                    <v-btn rounded v-if=" Object.keys(getFilteredValues(selectedValues)).length " @click="clearValues" block color="red" class="mb-8">
                      Reset
                      <v-icon>mdi-autorenew</v-icon>
                    </v-btn>
                  </div>
                </div>
              </selected-filters>
              <div v-if="app == 'kb-drugs'" class="drug-filters">
                <single-dropdown-list
                  :innerClass="{ input: 'reactive-search-input' }"
                  :showIcon="false"
                  :URLParams="true"
                  title="Filter by disease"
                  filterLabel="Diseases"
                  :showClear="true"
                  :size="100000"
                  placeholder=""
                  componentId="DrugSearchDisesasesSensor"
                  :autosuggest="true"
                  :showSearch="true"
                  :showCount="false"
                  sortBy="asc"
                  :dataField="`DC_Ind_EN.keyword`"
                  class="mb-4 grow z-index"
                />
                <h3 class="title font-weight-semibold text-h4 mt-12">
                  Filter by drug category
                </h3>
                <data-search
                  style="display: none;"
                  componentId="CategorySensor"
                  :URLParams="false"
                  :dataField="['EPG_drug_categories_string']"
                  :customQuery="catCustomQuery"
                  :autosuggest="false"
                  :highlight="false"
                  :filterLabel="$t('category')"
                  :value="categoryValue"
                  :showFilter="true"
                />
                <DrugCategoriesFilter
                  forceLocale="en"
                  @selected-category="selectDrugCategory"
                  :activeCategory="categoryValueOriginal"
                />
              </div>
              <div v-else class="genes-filter">
                <single-dropdown-list
                  :innerClass="{ input: 'reactive-search-input' }"
                  :showIcon="false"
                  :URLParams="true"
                  title="Filter by disease"
                  filterLabel="Diseases"
                  :showClear="true"
                  :size="100000"
                  placeholder=""
                  :showCount="false"
                  sortBy="asc"
                  componentId="GeneSearchDisesasesSensor"
                  :autosuggest="true"
                  :showSearch="true"
                  :showFilter="true"
                  :dataField="`Dis_EN.keyword`"
                  class="mb-4 grow z-index"
                />
              </div>
            </v-card-text>
          </v-card>
          <v-card class="pa-0">
            <v-card-title class="solid-heading rounded-lg mb-4 py-3">
              <h3 class="white--text font-weight-regular">
                Summaries
              </h3>
            </v-card-title>
            <v-card-text>
              <v-list-item v-if="app == 'kb-drugs'">
                <v-list-item-content>
                  <v-list-item-title>
                    <a @click="$router.push({ name: 'ApdxDrugClass' })"
                      >Drugs Classification</a
                    >
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="app == 'kb-genes'">
                <v-list-item-content>
                  <v-list-item-title>
                    <a @click="$router.push({ name: 'ApdxDiseasesGenes' })">
                      Diseases / Genes</a
                    >
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
          <v-card class="pa-0">
            <v-card-text> *{{ $t("Solo disponible en ingles") }} </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- resultados -->
    </reactive-base>
  </v-container>
</template>

<script>
import { CRED, TYPE, URL } from "@/helpers/constants";
import { mapGetters } from "vuex";
import LikeRadioButtons from "@/components/mylogy/LikeRadioButtons";
import SearchbaseDrugResultItem from "@/components/mylogy/explorer/SearchbaseDrugResultItem";
import SearchbaseGeneResultItem from "@/components/mylogy/explorer/SearchbaseGeneResultItem";
import PageTopTitle from "@/components/mylogy/PageTopTitle";
import FondoCustom from "@/components/mylogy/FondoCustom.vue";
import DrugCategoriesFilter from "@/components/mylogy/DrugCategoriesFilter.vue";

export default {
  props: {
    app: {
      type: String,
      default: "kb-drugs"
    }
  },
  components: {
    LikeRadioButtons,
    SearchbaseDrugResultItem,
    SearchbaseGeneResultItem,
    DrugCategoriesFilter,
    PageTopTitle,
    FondoCustom
  },
  data() {
    return {
      credentials: CRED,
      searchBaseKey: 1,
      type: TYPE,
      url: URL,
      search: null,
      categoryValue: "",
      categoryValueOriginal: ""
    };
  },
  computed: {
    ...mapGetters("drugCategories", ["drugCategoriesDict"]),
    geneDiseaseDatafields() {
      return [`Dis_EN`];
    },
    drugDiseaseDatafields() {
      return [`DC_Ind_EN`, `DC_Off_EN`, `DC_Off_EN`];
    },
    reactiveListReact() {
      if (this.app == "kb-drugs") {
        return {
          and: ["SearchSensor", "DrugSearchDisesasesSensor", "CategorySensor"]
        };
      } else {
        return { and: ["SearchSensor", "GeneSearchDisesasesSensor"] };
      }
    },
    searchPlaceholder() {
      if (this.app == "kb-drugs") {
        return "Search Drugs";
      } else {
        return "Search Genes";
      }
    },
    datafields() {
      if (this.app == "kb-drugs") {
        return ["Drug_Name_EN", "Brandnames"];
      } else {
        return [
          "Alternative_Names",
          "alternative_symbols",
          "Symbol",
          `Gene_Name_EN`,
          "OMIM",
          "Locus"
        ];
      }
    }
  },
  mounted() {},
  methods: {
    catCustomQuery: (value, props) => {
      return {
        query: {
          wildcard: {
            EPG_drug_categories_string: {
              value: `*${value}*`
            }
          }
        }
      };
    },
    clearFilter(componentId, callback) {
      callback(componentId, null);
      if (componentId == "CategorySensor") {
        this.categoryValue = "";
        this.categoryValueOriginal = "";
      }
    },
    getFilteredValues(values = {}) {
      let self = this;
      const filteredValues = {};
      Object.keys(values).forEach(componentId => {
        if (
          values[componentId].showFilter &&
          (Array.isArray(values[componentId].value)
            ? values[componentId].value.length
            : !!values[componentId].value)
        ) {
          filteredValues[componentId] = values[componentId];
          if (componentId == "CategorySensor") {
            const catId = filteredValues[componentId].value
              .replace("root", "")
              .replace(/o/g, ".")
              .slice(0, -1);
            console.log(catId);
            filteredValues[componentId].showValue =
              self.drugCategoriesDict[catId]["en"];
          }
        }
      });
      return filteredValues;
    },
    selectDrugCategory(cat) {
      if (cat != "") {
        this.categoryValueOriginal = cat;
        this.categoryValue =
          "root" + cat.replace(/\./g, "o") + ((cat != "" && "o") || "");
      } else {
        this.categoryValueOriginal = "";
        this.categoryValue = "";
      }
    },
    defaultQuery() {
      if (this.app == "kb-drugs") {
        return {
          query: {
            term: { Has_EPG: true }
          },
          sort: {
            "Drug_Name_EN.keyword": { order: "asc" }
          }
        };
      } else {
        return {
          query: {
            term: { Has_EPG: true }
          },
          sort: {
            "Symbol.keyword": { order: "asc" }
          }
        };
      }
    },
    buttonSelected(button) {
      this.$router.push({ name: "Explorer", params: { app: button.key } });
      this.searchBaseKey++;
    }
  }
};
</script>

<style lang="scss">
.searchbase-results {
  width: 100%;
  background: transparent !important;
}

.search-bar .buttons button {
  flex-grow: 1;
  margin-right: 10px !important;
}
#SearchSensor-input {
  background-image: url("~@/assets/img/search-icon-white.png") !important;
  background-size: 25px !important;
  background-repeat: no-repeat !important;
  background-position-y: center !important;
  background-position-x: 10px !important;
  padding-left: 50px !important;
}
.drug-filters, .genes-filter {
  ul {
    padding-left: 0;
  }
}

/* Paginacion pagination */
.results .css-1oof9tm {
  a {
    background: none;
    color: #4d2cb9;
  }
  :focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
}
</style>
<style lang="scss" scoped></style>
